.common-promotion {
    .promotion-categories {
        overflow: hidden;
        position: relative;
        padding: 75px 0;
        
        &__title {
            color: #0D0802;
            font-size: 36px;
            font-weight: 700;
            line-height: 120%; /* 43.2px */
            text-transform: uppercase;
        }

        &__inner {
            gap: 45px;
            height: auto;
            padding-top: 27px;
        }
        &__card {
            width: 104px;
            height: auto;
            display: flex;
            flex-direction: column;
            gap: 16px;

            &.active {
                .promotion-categories__card-info-title {
                    color: #D00A10;
                }
                .promotion-categories__card-image {
                    box-shadow: 0px 12px 32px 0px rgba(0, 0, 0, 0.10);
                    @include r(375) {
                        box-shadow: 0px 12px 15px 0px rgba(0,0,0,.1);
                    }
                }
            }

            &-image {
                width: 104px;
                height: 104px;
                overflow: hidden;
                border-radius: 50%;

                background: #fff;
            }
            &-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
                flex: 1;

                &-title {
                    color: #0D0802;
                    text-align: center;
                    font-size: 10px;
                    font-weight: 900;
                    line-height: 120%; /* 12px */
                    letter-spacing: 0.5px;
                    text-transform: uppercase;

                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }
                &-quantity {
                    color: #696663;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 900;
                    line-height: 120%; /* 14.4px */
                    letter-spacing: 0.6px;
                    text-transform: uppercase;

                    margin-top: auto;
                }
            }
            @include r(375) {
                &-image {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &__slider {
            position: initial;
            @include r(375) {
                position: relative;
            }
            .slick-list {
                overflow: visible;

                @include r(375) {
                    overflow: hidden;
                }
            }
            .slick-track {
                z-index: 100;
                display: flex;
                gap: 45px;

                @include r(1224) {
                    gap: 20px;
                }
                @include r(767) {
                    gap: 8px;
                }
            }
        }
        @include r(767) {
            padding: 24px 0;

            &__title {
                font-size: 28px;
            }
            &__inner {
                padding-top: 16px;
            }
        }
    }
}