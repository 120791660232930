.common-home {
  #popup_form {
    display: none;
    max-width: 568px;
    border-radius: 15px;
    width: 100%;
    padding: 30px 40px 40px;
    position: relative;
    .fancybox-close-small {
      display: none;
    }
    .cooperation-us__content {
      max-width: 100%;
      .heading__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.025em;

        padding-top: 0;
        margin-bottom: 0;
      }
    }
    .cooperation_us_form-row {
      display: flex;
      gap: 40px;

      &:first-child {
        .input_container {
          margin-top: 15px;
        }
      }
    }
    .input_container {
      flex-grow: 1;
      margin-top: 24px;

      span {
        margin-top: 6px;
        font-size: 14px;
        line-height: 150%;
        color: #D00A10;
      }

      label {
        color: #A4A4A5;
        font-size: 16px;
        line-height: 100%;
        margin-bottom: 8px;
      }
      input {
        text-overflow: ellipsis;
        &::placeholder {
          color: #DEE0E3;
        }
      }
      &.sphere {
        input {
          padding-right: 30px;
        }
      }
      &.required {
        label:after {
          content: " *";
          color: #e9573f;
        }
      }
    }
    .cooperation_us_form-coop {
      &-type {
        &__title {
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
          letter-spacing: 0.01em;

          margin-top: 28px;
        }
        fieldset {
          border: none;
          padding: 0;
          margin: 0;
          margin-top: 20px;

          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .cooperation_us-radio {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 0 16px;
          border: 1px #DEE0E3 solid;
          border-radius: 4px;
          input {
            position: relative;
            background: #fff;
            width: 16px;
            height: 16px;
            padding: 7px;
            appearance: none;
            background-color: #fff;
            color: #D00A10;
            border: 1px solid #DEE0E3;
            border-radius: 50%;

            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 2px;
              left: 2px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              transform: scale(0);
              transition: 120ms transform ease-in-out;
              box-shadow: inset 1em 1em #D00A10;
            }
            &:checked::before {
              transform: scale(1);
            }
            &:checked {
              border-color: #D00A10;
            }
          }
          label {
            font-size: 16px;
            line-height: 90%;
            width: 100%;
            padding: 16px 0;
          }
        }
        .cooperation_us-radio {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 0 16px;
          border: 1px #DEE0E3 solid;
          border-radius: 4px;
        }
      }
    }
    .cooperation_us_form-privacy {
      fieldset {
        border: none;
        padding: 0;
        margin: 0;
        margin-top: 35px;
  
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      
      .cooperation_us_privacy {
        box-sizing: border-box;
        --background-color: #fff;
        --checkbox-height: 14px;
      }

      @-moz-keyframes dothabottomcheck-19 {
        0% {
          height: 0;
        }
        100% {
          height: calc(var(--checkbox-height) / 2);
        }
      }

      @-webkit-keyframes dothabottomcheck-19 {
        0% {
          height: 0;
        }
        100% {
          height: calc(var(--checkbox-height) / 2);
        }
      }

      @keyframes dothabottomcheck-19 {
        0% {
          height: 0;
        }
        100% {
          height: calc(var(--checkbox-height) / 2);
        }
      }

      @keyframes dothatopcheck-19 {
        0% {
          height: 0;
        }
        50% {
          height: 0;
        }
        100% {
          height: calc(var(--checkbox-height) * 1.2);
        }
      }

      @-webkit-keyframes dothatopcheck-19 {
        0% {
          height: 0;
        }
        50% {
          height: 0;
        }
        100% {
          height: calc(var(--checkbox-height) * 1.2);
        }
      }

      @-moz-keyframes dothatopcheck-19 {
        0% {
          height: 0;
        }
        50% {
          height: 0;
        }
        100% {
          height: calc(var(--checkbox-height) * 1.2);
        }
      }
      .cooperation_us_privacy-checkbox {
        p {
          position: absolute;
          width: max-content;
          bottom: 50%;
          transform: translateY(50%);
          left: 26px;

          font-size: 16px;
          line-height: 90%;

          a {
            color: #D00A10;
          }
        }
      }

      .cooperation_us_privacy input[type=checkbox] {
        display: none;
      }

      .cooperation_us_privacy .cooperation_us_privacy-checkbox {
        height: var(--checkbox-height);
        width: var(--checkbox-height);
        background-color: transparent;
        border: 2px solid #A4A4A5;
        border-radius: 4px;
        position: relative;
        display: inline-block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-transition: border-color ease 0.2s;
        -o-transition: border-color ease 0.2s;
        -webkit-transition: border-color ease 0.2s;
        transition: border-color ease 0.2s;
        cursor: pointer;
      }
      .cooperation_us_privacy .cooperation_us_privacy-checkbox::before,
      .cooperation_us_privacy .cooperation_us_privacy-checkbox::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: calc(var(--checkbox-height) * .18);
        background-color: #D00A10;
        display: inline-block;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        -o-transform-origin: left top;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 3px;
        content: " ";
        -webkit-transition: opacity ease 0.5;
        -moz-transition: opacity ease 0.5;
        transition: opacity ease 0.5;
      }
      .cooperation_us_privacy .cooperation_us_privacy-checkbox::before {
        top: calc(var(--checkbox-height) * .7);
        left: calc(var(--checkbox-height) * .4);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
      .cooperation_us_privacy .cooperation_us_privacy-checkbox::after {
        top: calc(var(--checkbox-height) * .35);
        left: calc(var(--checkbox-height) * .05);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      .cooperation_us_privacy input[type=checkbox]:checked + .cooperation_us_privacy-checkbox,
      .cooperation_us_privacy .cooperation_us_privacy-checkbox.checked {
        border-color: #D00A10;
      }
      .cooperation_us_privacy input[type=checkbox]:checked + .cooperation_us_privacy-checkbox::after,
      .cooperation_us_privacy .cooperation_us_privacy-checkbox.checked::after {
        height: calc(var(--checkbox-height) / 2);
        -moz-animation: dothabottomcheck-19 0.2s ease 0s forwards;
        -o-animation: dothabottomcheck-19 0.2s ease 0s forwards;
        -webkit-animation: dothabottomcheck-19 0.2s ease 0s forwards;
        animation: dothabottomcheck-19 0.2s ease 0s forwards;
      }
      .cooperation_us_privacy input[type=checkbox]:checked + .cooperation_us_privacy-checkbox::before,
      .cooperation_us_privacy .cooperation_us_privacy-checkbox.checked::before {
        box-shadow: 0 0 0 calc(var(--checkbox-height) * .05) var(--background-color);
        height: calc(var(--checkbox-height) * 0.7);
        -moz-animation: dothatopcheck-19 0.4s ease 0s forwards;
        -o-animation: dothatopcheck-19 0.4s ease 0s forwards;
        -webkit-animation: dothatopcheck-19 0.4s ease 0s forwards;
        animation: dothatopcheck-19 0.4s ease 0s forwards;
      }

    }
    
    .fancybox-close-small {
      padding: 0;
      top: 20px;
      right: 20px;
    }
    .send-application__btn {
      margin-top: 40px;
      padding: 12px 16px;

      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: inherit;
      text-transform: none;
      border-radius: 3px
    }
    //RESPONSIVE
    @media screen and (max-width: 1024px) {
      padding: 30px 50px;
      .heading__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 130%;
      }
      .cooperation_us_form {
        margin-top: 30px;
        flex-direction: column;
        .input_container {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      height: 100%;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 16px;
      .heading__title {
        text-align: left;
        font-size: 24px!important;
        padding-top: 30px;
        letter-spacing: 0.07em !important;
      }
      .fancybox-close-small {
        top: 10px;
        right: 10px;
      }
      .cooperation_us_form {
        margin-top: 0;
      }
      .cooperation_us_form-row {
        flex-direction: column;
        gap: 0;

        &:first-child {
          .input_container {
            margin-top: 32px;
          }
        }
      }
      .cooperation_us_form-coop-type__title {
        margin-top: 55px;
      }
      .cooperation_us_form-privacy fieldset {
        margin-top: 53px;
        gap: 10px;
      }
      .send-application__btn {
        width: 100%;
        margin: 0 auto;
        margin-top: 70px;
      }
      .cooperation_us_form-privacy{
        fieldset {
          flex-direction: column-reverse;
        }
        .cooperation_us_privacy-checkbox {
          p {
            max-width: 300px;
            font-size: 14px;
            line-height: 120%;
          }
        }
      }
      .input_container {
        margin-top: 32px;
      }
    }
  }
}