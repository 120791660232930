$colorBlack: #000;
$colorWhite: #FFF;
$colorGrey: #666;
$colorLightGrey: #999;
$colorDarkGrey: #333;
$colorGreen: #0C0;
$colorLightGreen: lighten($colorGreen, 10);
$colorDarkGreen: darken($colorGreen, 10);
$colorRed: #C00;
$colorLightRed: lighten($colorRed, 10);
$colorDarkRed: darken($colorRed, 10);

$colorMainRed: #D00A10;


html,
.common-home {
	height: 100%;
}
.common-home {
	background: #F4F4F4;
	color: #0D0802;
	font-size: 18px;
	font-family: 'FuturaPT';
	font-weight: 400;
	line-height: 1.25;
}
* {
	outline: none;
	box-sizing: border-box;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}
.common-home {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: 1em;
		line-height: 1;
		margin: 0;
	}
	img {
		vertical-align: top;
		max-width: 100%;
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
	ul,
	ol {
		list-style-type: none;
		list-style-position: inside;
		padding: 0;
		margin: 0;
	}
	p {
		margin: 0;
	}
	button {
		outline: none;
		&:focus {
			outline: none;
		}
	}
	[class^='icon-'],
	[class*=' icon-'] {
		position: relative;
		vertical-align: middle;
	}
	// include @extend %image-bg;
	%image-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	.wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		min-width: 320px;
		min-height: 100%;
		overflow: hidden;
	}
	.container {
		max-width: 1508px;
		padding: 0 50px;
		margin: auto;
	}
	._container {
		max-width: 1260px;
		margin: 0 auto;
		padding: 0 20px;
	}

	.blocks__title {
		font-weight: 700;
		font-size: 36px;
		line-height: 140%;
		text-transform: uppercase;
	}

	.reset_input {
		height: 48px;
		border-color: #DEE0E3;
		border-radius: 4px;
	}

	.__icon-close {
		padding: 15px;
		position: absolute;
		top: 17px;
		right: 17px;
		cursor: pointer;
		background-color: $colorWhite;
		border: none;
		&:before, &:after {
		  content: '';
		  position: absolute;
		  top: 14px;
		  left: 8px;
		  display: block;
		  width: 14px;
		  height: 2px;
		  background: #A4A4A5;
		  border-radius: 2px;
		  transition: all .15s linear;
		}
		&:before {
		  transform: rotate(45deg);
		}
		&:after {
		  transform: rotate(-45deg);
		}
		&:hover {
		  &:before, &:after {
			  background: #0d0802;
		  }
		}
	  }

	@media screen and (max-width: 1024px) {
		.container {
			padding: 0 30px;
		}
		.blocks__title {
			font-size: 26px;
		}
	}
	@media screen and (max-width: 767px) {
		.container {
			padding: 0 15px;
		}
		.blocks__title {
			font-size: 22px;
		}
		.__icon-close {
			top: 11px;
			right: 11px;
			&:before, &:after {
				width: 24px;
				left: 5px;
			}
		}
	}
	@media (max-width: 479.98px) {
		._container {
			padding: 0 15px;
		}
	}
}

.common-promotion {
	ol {
		list-style-type: decimal;
		list-style-position: inside;
	}
	ul {
		list-style-type: disc;

		li {
			list-style: disc;
			list-style-position: inside;
		}
	}
	.main-info {
        display: flex;
		flex-direction: column;
    }
	.breadcrums-special {
		.breadcrums {
			padding: 32px 0 40px;

			@include r(767) {
				padding: 10px 0;
				border: 1px solid #DEE0E3;
			}
		}
		.breadcrums__item {
			color: #A4A4A5;
			font-size: 14px;
			font-weight: 400;
			line-height: 142.8%; /* 19.992px */

			@include r(767) {
				font-size: 18px;
			}
		}
		.breadcrums__text {
			color: #A4A4A5;
		}
	}
}