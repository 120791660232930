.common-home {
    .advantages {
        margin: 94px 0;

        &__cards {
            margin-top: 47px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 32px;
            &-item {
                padding: 42px 21px 28px;

                background-color: $colorWhite;
                border-radius: 16px;

                text-align: center;

                img {
                    width: 60px;
                    height: 60px;
                }

                h4 {
                    margin-top: 24px;
                    margin-bottom: 4px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 150%;
                    text-transform: uppercase;
                }
                p {
                    line-height: 140%;
                }
            }
        }

        @media screen and (max-width: 1199px) {
            &__cards {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        @media screen and (max-width: 1024px) {
            &__cards {
                grid-template-columns: 1fr 1fr;
            }
        }
        @media screen and (max-width: 767px) {
            margin: 100px 0;
            &__cards {
                gap: 15px;
                &-item {
                    padding: 30px 15px 30px;
                    h4 {
                        font-size: 14px;
                        line-height: 140%;
                    }
                    p {
                        font-size: 16px;
                        line-height: 130%;
                    }
                }
            }
        }
        @media screen and (max-width: 470px) {
            &__cards {
                grid-template-columns: 1fr;
            }
        }
    }
}