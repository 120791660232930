.common-promotion {
    .promotion-catalog {
        &__inner {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 30px;
            font-family: Arial;
            color: #3C3C3C;

            .to-cart-actions-row-body-main-block-catalog {
                display: -ms-flexbox;
                display: flex;
                width: 100%;
                -webkit-column-gap: 5px;
                -moz-column-gap: 5px;
                column-gap: 5px;
            }
            .to-cart-actions-row-body-main-block-catalog__count {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 48px;
                flex: 0 0 48px;
                height: 48px;

                input {
                    width: 100%;
                    max-width: 100%;
                    min-width: 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                    height: 100%;
                    border: 1px solid #dee0e3;
                    border-radius: 4px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 32px;
                    color: #000;

                    padding: 0;
                }
                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
            }
            .to-cart-actions-row-body-main-block-catalog__cart {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                background: #d00a10;
                border-radius: 4px;
                height: 48px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                text-align: center;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: #fff;
            }
            .actions-row-body-main-block-catalog__like {
                margin-top: 10px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                text-align: center;
                height: 48px;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: #a4a4a5;
                -webkit-column-gap: 10px;
                -moz-column-gap: 10px;
                column-gap: 10px;
                background: #fff;
                border: 1px solid #dee0e3;
                border-radius: 4px;
            }
            .info-row-body-main-block-catalog__list {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                row-gap: 5px;
            }
            .info-row-body-main-block-catalog__item {
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-column-gap: 5px;
                -moz-column-gap: 5px;
                column-gap: 5px;
            }
            .info-row-body-main-block-catalog__name {
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #3c3c3c;
            }
            .info-row-body-main-block-catalog__value {
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                color: #0d0802;
            }
            .info-row-body-main-block-catalog__icon {
                cursor: pointer;
            }
            .info-row-body-main-block-catalog__links {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: start;
                -ms-flex-align: start;
                align-items: flex-start;
                row-gap: 2px;
            }
            .info-row-body-main-block-catalog__link {
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                color: #d00a10;
                display: block;

                &:hover {
                    text-decoration: underline;
                }
            }
            .actions-row-body-main-block-catalog__stock {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                text-align: right;
                -webkit-column-gap: 3px;
                -moz-column-gap: 3px;
                column-gap: 3px;
            }
            .actions-row-body-main-block-catalog__price-valid {
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                color: #3c3c3c;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-column-gap: 3px;
                -moz-column-gap: 3px;
                column-gap: 3px;
                flex-wrap: wrap;
            }
            .actions-row-body-main-block-catalog__stock-name {
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
                color: #3c3c3c;
            }
            .actions-row-body-main-block-catalog__stock-value {
                font-weight: 700;
                font-size: 14px;
                line-height: 140%;
            }
            .actions-row-body-main-block-catalog__stock-value._present {
                color: #38b88a;
            }
        }
        &__item {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 16px;

            border: 1px solid #DEE0E3;
            background: #FFF;
            padding: 40px 16px 24px;

            &-tags {
                position: absolute;
                top: 10px;
                left: 16px;

                display: flex;
                flex-direction: column;
                gap: 5px;
            }
            .promotion-catalog-tag {
                padding: 4px 8px;
                background: #FAD720;
                border-radius: 4px;

                color: #0D0802;
                font-size: 12px;
                font-weight: 700;
                line-height: normal;

                &.promotion {
                    background: #FAD720;
                }
            }

            &-image {
                width: 194px;
                height: auto;
                align-self: center;

                margin-bottom: 40px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    -webkit-transition: all .3s ease 0s;
                    -o-transition: all .3s ease 0s;
                    transition: all .3s ease 0s;
                }
                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }
            }
            &-title {
                font-size: 16px;
                font-weight: 700;
                line-height: 140%;
                color: #0d0802;
                
                min-height: 44px;
                margin-top: auto;

                &:hover {
                    text-decoration: underline;
                }
            }
            &-retail_price {
                font-size: 14px;
                font-weight: 700;
                line-height: 140%;

                margin-bottom: 4px;
            }
            &-prices {
                color: #D00A10;
                font-size: 16px;
                font-weight: 700;
                line-height: 140%;
                margin-top: -16px;

                display: flex;
                gap: 5px;
                align-items: center;

                &-old {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 140%; /* 16.8px */
                    text-decoration: line-through;
                    color: #3C3C3C;
                }
            }
        }
    }
}