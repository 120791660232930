.common-promotion {
    .promotions-action {
        background: #fff;
        flex: 1;
    }
    .promotions {
        padding: 37px 0;
        
        &__inner {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 32px;
            row-gap: 65px;
            padding-bottom: 36px;
        }
        &__card {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &-image {
                width: 100%;

                img {
                    border-radius: 14px;
                }
            }
            &-info {
                display: flex;
                flex-direction: column;
                gap: 8px;

                &-title {
                    color: #0D0802;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 150%; /* 27px */
                }
                &-date {
                    color: #696663;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 142.8%; /* 19.992px */
                }
            }
        }

        @include r(1200) {
            &__inner {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 20px;
                row-gap: 40px;
            }
        }
        @include r(900) {
            &__inner {
                grid-template-columns: 1fr 1fr;
            }
        }
        @include r(500) {
            padding: 24px 0;
            &__inner {
                grid-template-columns: 1fr;
                row-gap: 24px;
            }
        }
    }
    .more-row-body-main-block-catalog__button {
        background: #fff;
        border: 1px solid #dee0e3;
        border-radius: 4px;
        height: 40px;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #a4a4a5;
        cursor: pointer;
    }
}