.common-home {
    .banner-sm {
        margin: 94px 0;
        background-position: left;
        border-radius: 16px;
        position: relative;
        &__container {
            padding: 54px 78px;
            text-align: right;
        }
        &__title {
            font-weight: 700;
            font-size: 36px;
            line-height: 120%;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        &__img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }

        @media screen and (max-width: 1199px) {
            &__title {
                font-size: 26px;
            }
        }
        @media screen and (max-width: 1024px) {
            &__title {
                font-size: 20px;
            }
        }
        @media screen and (max-width: 767px) {
            background-position-x: -35px !important;
            margin: 100px 0;
            &__container {
                padding: 30px;
            }
        }
        @media screen and (max-width: 540px) {
            background-position-x: -65px !important;
            &__title {
                font-size: 18px;
            }
        }
        @media screen and (max-width: 470px) {
            &__title {
                font-size: 16px;
            }
        }
    }
}