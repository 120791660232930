.common-home {
    .assortment {
        margin: 72px 0 94px;

        .blocks__title {
            max-width: 780px;
            line-height: 120%;
        }
        &__items {
            margin: 78px 0 83px;
            display: flex;
            gap: 32px;
        }
        &__item {
            width: 25%;
            img {
                width: 60px;
                height: 60px;
            }
            p {
                margin-top: 20px;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.03em;
                text-transform: uppercase;
            }
        }
        @media screen and (max-width: 767px) {
            margin: 100px 0;

            &__items {
                margin: 70px 0 60px;
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            &__item {
                width: auto;
                p {
                    font-size: 16px;
                }
            }
        }
    }
}