.common-home {
    .footer {
        padding: 40px 0;
        background-color: #E9E9E9;
        color: #696663;

        .container {
            display: flex;
            justify-content: space-between;
            gap: 175px;
        }
        &-nav {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;

            &__elem {
                width: 184px;
                display: flex;
                flex-direction: column;
                gap: 16px;

                &-title {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    text-transform: uppercase;
                }
                &-item {
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 16px;
                    color: #696663;
                    transition: all .1s linear;

                    &:hover {
                        color: #0D0802;
                    }
                }
            }
        }
        &-info {
            width: 184px;
            display: flex;
            flex-direction: column;
            gap: 16px;

            font-weight: 300;
            font-size: 14px;
            line-height: 16px;

            &__phone {
                display: flex;
                gap: 7px;
                align-items: center;
                a {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 16px;
                    color: #696663;
                }
            }
            &__message, &__soccial {
                p {
                    margin-bottom: 5px;
                }
                &-messangers, &-socials {
                    display: flex;
                    gap: 8px;
                }
            }
            &__pay {
                margin-top: 12px;
                display: flex;
                gap: 16px;
            }
        }
        @media screen and (max-width: 1024px) {
            .container {
                gap: 50px;
            }
            &-nav {
                display: grid;
                row-gap: 50px;
                grid-template-columns: 1fr 1fr;
                flex-grow: 1;

                &__elem {
                    &-title {
                        font-size: 18px;
                    }
                    &-item {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }

            }
            &-info {
                font-size: 18px;
                line-height: 20px;
                
                &__phone {
                    a {
                        font-size: 18px;
                    }
                }
                &__message, &__soccial {
                    p {
                        margin-bottom: 10px;
                    }
                    &-messangers, &-socials {
                        gap: 20px;
                        a {
                            img {
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .container {
                flex-direction: column;
                gap: 100px;
            }
            &-nav {
                grid-template-columns: 1fr;
            }
            &-info {
                width: 100%;
                &__message, &__soccial {
                    &-messangers, &-socials {
                        gap: 30px;
                        margin-bottom: 15px;
                        a {
                            img {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
                &__pay {
                    margin-top: 30px;
                    gap: 30px;

                    a {
                        img {
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}