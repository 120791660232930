.common-home {
    .header {
        background-color: $colorMainRed;
        min-height: 72px;
        padding: 10px 0 13px;

        img {
            width: 184px;
            height: auto;
        }
    }
}