.common-home {
    .banner-big {
        position: relative;
        margin: 40px 0;
        padding: 64px 76px 70px;
        color: #FFFFFF;
        background: linear-gradient(180deg, #D3283F 0%, #090102 100%);
        border-radius: 15px;
        overflow: hidden;

        &__container {
            position: inherit;
            z-index: 10;
        }
        &__title {
            font-weight: 700;
            font-size: 86px;
            line-height: 90px;
            text-transform: uppercase;
        }
        &__subtitle{
            margin-top: 15px;
            margin-bottom: 25px;

            font-weight: 700;
            font-size: 42px;
            line-height: 42px;
            text-transform: uppercase;
        }
        &__text {
            max-width: 570px;

            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
        }
        &__img {
            position: absolute;
            bottom: 0;
            right: 0;

            height: 100%;
            z-index: 1;
        }
        .send-application__btn {
            margin-top: 57px;
            padding: 12px 16px;

            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: inherit;
            text-transform: none;
            border-radius: 3px
        }

        @media screen and (max-width: 1440px) {
            padding: 65px;

            &__title {
                font-size: 66px;
                line-height: 70px;
            }
            &__subtitle{
                margin-bottom: 20px;
                font-size: 22px;
                line-height: 22px;
            }
            &__text {
                max-width: 470px;
    
                font-size: 20px;
                line-height: 26px;
            }
            .send-application__btn {
                margin-top: 35px;
            }
        }
        @media screen and (max-width: 1199px) {
            &__img {
                height: 90%;
            }
        }
        @media screen and (max-width: 1024px) {
            padding: 40px;
            
            &__title {
                font-size: 56px;
                line-height: 50px;
            }
            &__subtitle{
                font-size: 20px;
                line-height: 22px;
            }
            &__text {
                max-width: 380px;
    
                font-size: 16px;
                line-height: 24px;
            }
            &__img {
                height: 85%;
            }
            .send-application__btn {
                margin-top: 35px;
            }
        }
        @media screen and (max-width: 767px) {
            padding: 30px;
            
            &__title {
                font-size: 34px;
                line-height: 40px;
            }
            &__subtitle{
                margin-top: 10px;
                margin-bottom: 15px;
                font-size: 18px;
                line-height: 22px;
            }
            &__text {
                max-width: 100%;
    
                font-size: 15px;
                line-height: 20px;
            }
            &__img {
                height: auto;
                width: 65%;
            }
            .send-application__btn {
                margin-bottom: 150px;
            }
        }
        @media screen and (max-width: 470px) {
            &__img {
                width: 230px;
            }
            &__text {
                margin-top: 15px;
                &:first-of-type {
                    margin-top: 30px;
                }
            }
            .send-application__btn {
                padding: 12px 35px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
            }
        }
    }
}