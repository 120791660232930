
.common-home {
  .popup-success {
    //opacity: 0;
    //visibility: hidden;
    display: none;
    position: absolute;
    max-width: 568px;
    width: 100%;
    background-color: $colorWhite;
    border-radius: 15px;
    padding: 60px;
    overflow-y: hidden;

    transition-property: all;
    transition-timing-function: linear;
    transition-duration: 0.2s;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 150;
    .__icon-close {
      position: absolute;
      top: 17px;
      right: 17px;
      cursor: pointer;
      background-color: $colorWhite;
    }
    .fancybox-close-small {
      display: none;
    }
    .popup-success__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .__icon-check:before {
        font-size: 44px;
      }
      h2 {
        max-width: 300px;
        margin-top: 20px;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: 0.03em;
        text-align: center;
      }
      p {
        margin-top: 15px;
        text-align: center;
      }
    }
    .send-application__btn {
      display: block !important;
      margin-top: 50px;
      padding: 12px 22px;

      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: inherit;
      text-transform: none;
      border-radius: 3px
    }
    &.popup_open {
      opacity: 1;
      visibility: visible;
    }
    //RESPONSIVE

    @media screen and (max-width: 767px) {
      height: 100%;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 16px;
      left: 50%;
      padding: 60px 30px;
      position: relative;

      .popup-success__content {
        width: 100%;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h2 {
          font-weight: 600;
          font-size: 24px;
          line-height: 120%;
        }
        p{
          max-width: 219px;
          margin-top: 10px;
        }
      }
    }
  }
}