.common-home {
    .categories {
        margin: 157px 0 126px;

        &__items {
            display: flex;
            flex-wrap: wrap;
            gap: 50px;
            margin-top: 60px;
            margin-bottom: 107px;
        }
        &__item {
            width: 184px;
            img {
                transition: all .15s linear;
                max-width: 184px;
                max-height: 184px;
                width: fit-content;
                height: fit-content;
            }
            p {
                margin-top: 24px;

                font-weight: 600;
                font-size: 14px;
                line-height: 140%;
                text-align: center;
                letter-spacing: 0.02em;
                text-transform: uppercase;
                color: #000000;
            }
            &:hover {
                img {
                    scale: 1.15;
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin: 100px 0;

            &__items {
                margin-bottom: 60px;
                display: grid;
                gap: 15px;
                row-gap: 30px;
                grid-template-columns: 1fr 1fr;
                justify-items: center;
            }
        }
        @media screen and (max-width: 470px) {
            &__item {
                width: auto;

                p {
                    margin-top: 0;
                }
            }
        }
    }
}