.common-home {
    .partners {
        margin: 94px 0 72px;
        padding: 65px 0 112px;
        background-color: $colorWhite;
        &__items {
            margin-top: 82px;
        }
        &__item {
            img {
                width: auto;
                height: auto;
                margin: 0 auto;
            }
        }
        .slick-track {
            display: flex;
            align-items: center;
        }
        @media screen and (max-width: 767px) {
            margin: 100px 0;
            padding: 65px 0 90px;
            .slick-track {
                gap: 15px;
            }
        }
    }
}