.common-home {
	.btn {
		position: relative;
		background: $colorBlack;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		color: $colorWhite;
		font-size: 14px;
		font-weight: 400;
		font-family: "Open Sans", sans-serif;
		line-height: 1.25;
		text-align: center;
		text-decoration: none;
		border: 0;
		border-radius: 8px;
		box-shadow: none;
		padding: 0 15px;
		transition: background 0.3s ease;
		outline: none;
		cursor: pointer;
		&:hover,
		&:active {
			color: $colorWhite;
			text-decoration: none;
			outline: none;
		}
		&:focus {
			box-shadow: none;
			outline: none;
		}
		&-green {
			background: $colorGreen;
			color: $colorWhite;
			//&:hover,
			//&:active {
			//
			//}
		}
		&.disabled,
		&[disabled] {
			pointer-events: none;
			opacity: 0.5;
			cursor: auto;
		}
	}
	.send-application__btn {
		cursor: pointer;
		padding: 16px 40px;
		background-color: $colorMainRed;
		border: none;
		border-radius: 7px;

		font-weight: 600;
		font-size: 18px;
		line-height: 150%;
		letter-spacing: 0.06em;
		text-transform: uppercase;
		color: $colorWhite;

		transition: all .2s linear;

		&:hover {
			background-color: #a6090d;
		}
	}
}