.common-promotion {
    .promotion-info {
        flex: 1;
        background: #fff;
        padding: 60px 0;

        &__title {
            width: 100%;
            text-align: left;
            padding: 30px 0 45px;

            color: #0D0802;
            font-size: 28px;
            font-weight: 700;
            line-height: 120%; /* 33.6px */
            text-transform: uppercase;

            display: none;
        }
        &__banner {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
                border-radius: 16px;
            }
        }
        &__content {
            &:not(:first-child) {
                margin-top: 110px;
            }

            max-width: 760px;

            h4, h3, h2, h1 {
                color: #0D0802;
                font-size: 24px;
                font-style: normal;
                font-weight: 900;
                line-height: 150%; /* 36px */
                margin-bottom: 16px;
            }
            h1 {
                font-size: 32px;
            }
            h2 {
                font-size: 28px;
            }

            color: #0D0802;
            font-size: 18px;
            font-weight: 400;
            line-height: 140%;

            p + p, p + ol, ol + p, li + li {
                margin-top: 35px;
            }

            img {
                width: 100%;
                padding: 40px 0;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
        @include r(767) {
            padding: 30px 0;

            &__title {
                display: block;
            }

            &__banner {
                picture {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }
            }
            &__content {
                font-size: 16px;

                &:not(:first-child) {
                    margin-top: 35px;
                }

                p + p, p + ol, ol + p, li + li, ul {
                    margin-top: 30px;
                }
            }
        }
    }
}