$fontPathTpl: 'fonts/'
$fontNameTpl: 'svgfont'
@font-face
	font-family: $fontNameTpl
	src: url($fontPathTpl + $fontNameTpl + '.ttf') format('truetype')
	src: url($fontPathTpl + $fontNameTpl + '.woff') format('woff')
	font-weight: normal
	font-style: normal
	font-display: swap

$icon-i-close: "\EA01"


%icon
	font-family: $fontNameTpl
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-i-close:before
	content: $icon-i-close
