.common-home {
    .large_text {
        max-width: 1357px;
        margin-top: 122px;
        margin-bottom: 126px;
        margin-left: -2px;

        font-size: 12px;
        font-weight: 500;
        line-height: 140%;
        color: #0D0802;

        opacity: 0.5;

        ul {
            margin-top: 3px;
            margin-left: 6px;
            li {
                display: flex;
                gap: 7px;
                align-items: center;
                &:before {
                    content: "";
                    display: block;
                    width: 4px;
                    height: 4px;
                    background-color: #000;
                    border-radius: 50%;
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin: 100px 0;
        }
    }
}